@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.imageSwitcher {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  // Allow children divs to take all the space and stack on top of each other.
  > div {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: opacity 0;
    transition-delay: 0;
  }

  // The div on top has a transition and a z-index...
  > .top {
    opacity: 0;
    transition: opacity 0;
    border-radius: $space-sm;
    z-index: 2;
  }

  // ...and will only be opaque when having the `visible` class.
  > .visible {
    border-radius: $space-sm;
    opacity: 1;
    transition-delay: 0ms;
  }
}
